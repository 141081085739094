import { TawreedRoleName, UserPermissions } from "../../domain";
import { Routes } from "../../../../router";
import { TawreedActionName } from "../../../../common/actions";

export class PermissionsRepository {

    private readonly administrator: UserPermissions = {
        actions: [
            TawreedActionName.ReportsExport,
            TawreedActionName.DashboardCardsSales,
            TawreedActionName.DashboardCardsOrdersPlaced,
            TawreedActionName.DashboardCardsOrdersProcessing,
            TawreedActionName.DashboardCardsOrdersShipped,
            TawreedActionName.DashboardCardsOrdersReturnShipped,
            TawreedActionName.DashboardCardsPartners,
            TawreedActionName.DashboardChartOrders,
            TawreedActionName.DashboardChartOrdersTotals,
            TawreedActionName.DashboardChartPartners,
            TawreedActionName.DashboardChartOrdersPerZone,
            TawreedActionName.DashboardChartOrdersPerState,
            TawreedActionName.DashboardCardsOrdersReadyForDelivery,
            TawreedActionName.ProductsCreate,
            TawreedActionName.ProductsDetails,
            TawreedActionName.ProductsDelete,
            TawreedActionName.ProductsSubmit,
            TawreedActionName.StoreProductsCreate,
            TawreedActionName.StoreProductsDetails,
            TawreedActionName.StoreProductsDelete,
            TawreedActionName.StoreProductsSubmit,
            TawreedActionName.StoreProductsStageImport,
            TawreedActionName.StoreProductsStageApprove,
            TawreedActionName.StoreProductsStageReject,
            TawreedActionName.StoreProductsDownloadTemplate,
            TawreedActionName.StoreProductsActivate,
            TawreedActionName.StoreProductsDeactivate,
            TawreedActionName.UsersCreate,
            TawreedActionName.UsersDetails,
            TawreedActionName.UsersDelete,
            TawreedActionName.UsersSubmit,
            TawreedActionName.UsersChangePassword,
            TawreedActionName.StorePartnerCreate,
            TawreedActionName.CustomerPartnerCreate,
            TawreedActionName.PartnerInformation,
            TawreedActionName.StorePartnerDetails,
            TawreedActionName.CustomerPartnerDetails,
            TawreedActionName.PartnersDelete,
            TawreedActionName.StorePartnerSubmit,
            TawreedActionName.CustomerPartnerSubmit,
            TawreedActionName.PartnersCustomersVerify,
            TawreedActionName.PartnersCustomersCreateWallet,
            TawreedActionName.PartnersCustomersUploadLicenseContentId,
            TawreedActionName.PartnersCustomersUploadTadeRegisterContentId,
            TawreedActionName.PartnersFilesUpload,
            TawreedActionName.PartnersFilesDownload,
            TawreedActionName.PartnersStoresVerify,
            TawreedActionName.PartnersCustomersUploadLogo,
            TawreedActionName.CartsCreate,
            TawreedActionName.CartsDetails,
            TawreedActionName.CartsUpdate,
            TawreedActionName.CartsStoreCartDelete,
            TawreedActionName.CartsStoreCartItemDelete,
            TawreedActionName.OrdersDetails,
            TawreedActionName.OrdersConfirm,
            TawreedActionName.OrdersShip,
            TawreedActionName.OrdersDeliver,
            TawreedActionName.OrdersCancel,
            TawreedActionName.OrdersReturn,
            TawreedActionName.OrdersPrintStore,
            TawreedActionName.OrdersPrintCustomer,
            TawreedActionName.OrdersEditQuantity,
            TawreedActionName.OrderEditDiscountValue,
            TawreedActionName.OrdersEditBonus,
            TawreedActionName.OrdersEditRetailPrice,
            TawreedActionName.OrdersEditDiscountPercent,
            TawreedActionName.OrdersPartnersDetails,
            TawreedActionName.OrdersPartnersShipAll,
            TawreedActionName.OrdersPartnersReturnAll,
            TawreedActionName.OrdersPartnersPrintAll,
            TawreedActionName.OrdersPartnersPrint,
            TawreedActionName.OrdersReadyForDelivery,
            TawreedActionName.OrdersReturnCreate,
            TawreedActionName.FinanceDocumentsCreate,
            TawreedActionName.ReportsGenerate,
            TawreedActionName.StoreZonesCreate,
            TawreedActionName.StoreZonesDelete,
            TawreedActionName.ZonesCreate,
            TawreedActionName.ZonesDetails,
            TawreedActionName.ZonesDelete,
            TawreedActionName.ZonesSubmit,
            TawreedActionName.MarketingCampaign,
            TawreedActionName.TopUpPartnersCreate,
            TawreedActionName.TopUpPartnersDetails,
            TawreedActionName.TopUpPartnersSubmit,
            TawreedActionName.TopUpPartnersDelete,
            TawreedActionName.TopUpTransactionsDetails,
            TawreedActionName.TopUpPartnersCleanup,

            TawreedActionName.CategoryDetails,
            TawreedActionName.CategoryNew,
            TawreedActionName.CategorySubmit,
            TawreedActionName.CategoryDelete,


            TawreedActionName.BannerDetails,
            TawreedActionName.BannerNew,
            TawreedActionName.BannerSubmit,
            TawreedActionName.BannerDelete,
            TawreedActionName.WalletTransactionDetails,
            TawreedActionName.ImpoerHelperDetails,
            TawreedActionName.ImportUploadTask,

            TawreedActionName.PaymentPartnersCreate,
            TawreedActionName.PaymentPartnersDetails,
            TawreedActionName.PaymentPartnersDelete,
            TawreedActionName.PaymentPartnersSubmit,
            TawreedActionName.PaymentTransactionsDetails,
            TawreedActionName.PaymentOrderDetails,
            TawreedActionName.PaymentPartnersActivate,
            TawreedActionName.PaymentPartnersDeactivate,


            TawreedActionName.DeliveryPartnersCreate,
            TawreedActionName.DeliveryPartnersDetails,
            TawreedActionName.DeliveryPartnersDelete,
            TawreedActionName.DeliveryPartnersSubmit,
            TawreedActionName.DeliveryPartnersActivate,
            TawreedActionName.DeliveryPartnersDeactivate,

            TawreedActionName.SalesOfferDetails,
            TawreedActionName.SalesOfferCreate,
            TawreedActionName.SalesOfferSubmit,
            TawreedActionName.SalesOfferDelete,
            TawreedActionName.SalesOfferActivate,
            TawreedActionName.SalesOfferDeactivate,
            TawreedActionName.PartnerConnectionSetClassification,
            TawreedActionName.PartnerConnectionRemoveClassification,

        ],
        routes: [
            Routes.Login,
            Routes.Notifications,
            Routes.TawreedError,
            Routes.Dashboard,
            Routes.ProductsSearch,
            Routes.ProductsNew,
            Routes.ProductsDetails,
            Routes.UnverifiedProductsDetails,
            Routes.UnverifiedProductsSearch,
            Routes.StoreProductsSearch,
            Routes.ImportStoreProductsSearch,
            Routes.SellerStoreProductSearch,
            Routes.StoreProductsNew,
            Routes.StoreProductsDetails,
            Routes.UsersSearch,
            Routes.UsersNew,
            Routes.UsersDetails,
            Routes.PartnersCustomersSearch,
            Routes.PartnersCustomersNew,
            Routes.PartnersCustomersDetails,
            Routes.PartnersStoresSearch,
            Routes.PartnersStoresNew,

            Routes.PaymentPartnersSearch,
            Routes.PaymentPartnersNew,
            Routes.PaymentPartnersDetails,

            Routes.DeliveryPartnersSearch,
            Routes.DeliveryPartnersNew,
            Routes.DeliveryPartnersDetails,

            Routes.PartnersStoresDetails,
            Routes.SalesCartsSearch,
            Routes.SalesCartsNew,
            Routes.SalesCartsDetails,
            Routes.SalesOrdersSearch,
            Routes.SalesOrdersDetails,
            Routes.SalesOrdersPlacedSearch,
            Routes.SalesOrdersPlacedDetails,
            Routes.SalesOrdersProcessingSearch,
            Routes.SalesOrdersProcessingDetails,
            Routes.SalesOrdersShippedSearch,
            Routes.SalesOrdersReadySearch,
            Routes.SalesOrdersReadyDetails,
            Routes.SalesOrdersShippedDetails,
            Routes.FinanceDocumentType1,
            Routes.FinanceDocumentType2,
            Routes.FinanceDocumentType3,
            Routes.FinanceDocumentType4,
            Routes.FinanceDocumentType1Details,
            Routes.FinanceDocumentType2Details,
            Routes.FinanceDocumentType3Details,
            Routes.FinanceDocumentType4Details,
            Routes.FinanceDocumentTypeGeneralDetails,

            Routes.ReportsFinancialDocuments,
            Routes.ReportsFinancialStatementsPerPartner,
            Routes.ReportsFinancialStatementsPerAccount,
            Routes.ReportsSalesOrders,
            Routes.ReportsInvoices,
            Routes.ReportsTrialBalance,
            Routes.StoreZonesSearch,
            Routes.MarketingNotificationCampaign,
            Routes.TopUpPartnersSearch,
            Routes.WalletTransaction,
            Routes.WalletTransactionDetails,
            Routes.TopUpPartnersNew,
            Routes.TopUpPartnersDetails,
            Routes.TopUpTransactionsSearch,
            Routes.TopUpTransactionsDetails,
            Routes.SettingsCategory,
            Routes.SettingsCategoryDetials,
            Routes.SettingsCategoryNew,
            Routes.SettingsBanner,
            Routes.SettingsBannerNew,
            Routes.SettingsBannerDetials,
            Routes.ImportHelper,
            Routes.ImportHelperDetails,

            // Routes.SalesOfferSearch,
            // Routes.SalesOfferNew,
            // Routes.SalesOfferDetails,
            Routes.PaymentTransactionsSearch,
            Routes.PaymentTransactionDetails,
            Routes.PaymentOrdersSearch,
            Routes.PaymentOrderDetails,
            Routes.BrokenOrdersSearch,
            Routes.WrongOrderDetails,
            Routes.DeliveredOrdersSearch,
            Routes.PlacedOrdersSearch,
            Routes.ShippedOrdersSearch,
            Routes.PartnerConnectionsSearch
        ],
    }
    private readonly sysAdmin: UserPermissions = {
        actions: [
            TawreedActionName.ReportsExport,
            TawreedActionName.DashboardCardsSales,
            TawreedActionName.DashboardCardsOrdersPlaced,
            TawreedActionName.DashboardCardsOrdersProcessing,
            TawreedActionName.DashboardCardsOrdersShipped,
            TawreedActionName.DashboardCardsOrdersReturnShipped,
            TawreedActionName.DashboardCardsPartners,
            TawreedActionName.DashboardChartOrders,
            TawreedActionName.DashboardChartOrdersTotals,
            TawreedActionName.DashboardChartPartners,
            TawreedActionName.DashboardChartOrdersPerZone,
            TawreedActionName.DashboardChartOrdersPerState,
            TawreedActionName.DashboardCardsOrdersReadyForDelivery,
            TawreedActionName.ProductsCreate,
            TawreedActionName.PartnerInformation,
            TawreedActionName.ProductsDetails,
            TawreedActionName.ProductsDelete,
            TawreedActionName.ProductsSubmit,
            TawreedActionName.StoreProductsCreate,
            TawreedActionName.StoreProductsDetails,
            TawreedActionName.StoreProductsDelete,
            TawreedActionName.StoreProductsSubmit,
            TawreedActionName.StoreProductsStageImport,
            TawreedActionName.StoreProductsStageApprove,
            TawreedActionName.StoreProductsStageReject,
            TawreedActionName.StoreProductsDownloadTemplate,
            TawreedActionName.StoreProductsActivate,
            TawreedActionName.StoreProductsDeactivate,
            TawreedActionName.UsersCreate,
            TawreedActionName.UsersDetails,
            TawreedActionName.UsersDelete,
            TawreedActionName.UsersSubmit,
            TawreedActionName.UsersChangePassword,
            TawreedActionName.StorePartnerCreate,
            TawreedActionName.CustomerPartnerCreate,
            TawreedActionName.StorePartnerDetails,
            TawreedActionName.CustomerPartnerDetails,
            TawreedActionName.PartnersDelete,
            TawreedActionName.StorePartnerSubmit,
            TawreedActionName.CustomerPartnerSubmit,
            TawreedActionName.PartnersCustomersVerify,
            TawreedActionName.PartnersCustomersCreateWallet,
            TawreedActionName.PartnersCustomersUploadLicenseContentId,
            TawreedActionName.PartnersCustomersUploadTadeRegisterContentId,
            TawreedActionName.PartnersFilesUpload,
            TawreedActionName.PartnersFilesDownload,
            TawreedActionName.PartnersStoresVerify,
            TawreedActionName.PartnersCustomersUploadLogo,
            TawreedActionName.CartsCreate,
            TawreedActionName.CartsDetails,
            TawreedActionName.CartsUpdate,
            TawreedActionName.CartsStoreCartDelete,
            TawreedActionName.CartsStoreCartItemDelete,
            TawreedActionName.OrdersDetails,
            TawreedActionName.OrdersConfirm,
            TawreedActionName.OrdersShip,
            TawreedActionName.OrdersDeliver,
            TawreedActionName.OrdersCancel,
            TawreedActionName.OrdersReturn,
            TawreedActionName.OrdersPrintStore,
            TawreedActionName.OrdersPrintCustomer,
            TawreedActionName.OrdersEditQuantity,
            TawreedActionName.OrderEditDiscountValue,
            TawreedActionName.OrdersEditBonus,
            TawreedActionName.OrdersEditRetailPrice,
            TawreedActionName.OrdersEditDiscountPercent,
            TawreedActionName.OrdersPartnersDetails,
            TawreedActionName.OrdersPartnersShipAll,
            TawreedActionName.OrdersPartnersReturnAll,
            TawreedActionName.OrdersPartnersPrintAll,
            TawreedActionName.OrdersPartnersPrint,
            TawreedActionName.OrdersReadyForDelivery,
            TawreedActionName.OrdersReturnCreate,
            TawreedActionName.FinanceDocumentsCreate,
            TawreedActionName.ReportsGenerate,
            TawreedActionName.StoreZonesCreate,
            TawreedActionName.StoreZonesDelete,
            TawreedActionName.ZonesCreate,
            TawreedActionName.ZonesDetails,
            TawreedActionName.ZonesDelete,
            TawreedActionName.ZonesSubmit,
            TawreedActionName.MarketingCampaign,
            TawreedActionName.TopUpPartnersCreate,
            TawreedActionName.TopUpPartnersDetails,
            TawreedActionName.TopUpPartnersSubmit,
            TawreedActionName.TopUpPartnersDelete,
            TawreedActionName.TopUpTransactionsDetails,
            TawreedActionName.TopUpPartnersCleanup,
            TawreedActionName.CategoryDetails,
            TawreedActionName.CategoryNew,
            TawreedActionName.CategorySubmit,
            TawreedActionName.CategoryDelete,

            TawreedActionName.BannerDetails,
            TawreedActionName.BannerNew,
            TawreedActionName.BannerSubmit,
            TawreedActionName.BannerDelete,

            TawreedActionName.WalletTransactionDetails,
            TawreedActionName.ImpoerHelperDetails,
            TawreedActionName.ImportUploadTask,
            //payment
            TawreedActionName.PaymentPartnersCreate,
            TawreedActionName.PaymentPartnersDetails,
            TawreedActionName.PaymentPartnersDelete,
            TawreedActionName.PaymentPartnersSubmit,
            TawreedActionName.PaymentTransactionsDetails,
            TawreedActionName.PaymentOrderDetails,
            TawreedActionName.PaymentPartnersActivate,
            TawreedActionName.PaymentPartnersDeactivate,



            TawreedActionName.DeliveryPartnersCreate,
            TawreedActionName.DeliveryPartnersDetails,
            TawreedActionName.DeliveryPartnersDelete,
            TawreedActionName.DeliveryPartnersSubmit,
            TawreedActionName.DeliveryPartnersActivate,
            TawreedActionName.DeliveryPartnersDeactivate,

            TawreedActionName.SalesOfferDetails,
            TawreedActionName.SalesOfferCreate,
            TawreedActionName.SalesOfferSubmit,
            TawreedActionName.SalesOfferDelete,
            TawreedActionName.SalesOfferActivate,
            TawreedActionName.SalesOfferDeactivate,
            TawreedActionName.PartnerConnectionSetClassification,
            TawreedActionName.PartnerConnectionRemoveClassification,
        ],
        routes: [
            Routes.Login,
            Routes.Notifications,
            Routes.TawreedError,
            Routes.Dashboard,
            Routes.ProductsSearch,
            Routes.ProductsNew,
            Routes.ProductsDetails,
            Routes.UnverifiedProductsDetails,
            Routes.UnverifiedProductsSearch,
            Routes.ImportStoreProductsSearch,
            Routes.StoreProductsSearch,
            Routes.SellerStoreProductSearch,
            Routes.StoreProductsNew,
            Routes.StoreProductsDetails,
            Routes.UsersSearch,
            Routes.UsersNew,
            Routes.UsersDetails,
            Routes.PartnersCustomersSearch,
            Routes.PartnersCustomersNew,
            Routes.PartnersCustomersDetails,
            Routes.PartnersStoresSearch,
            Routes.PartnersStoresNew,
            Routes.PartnersStoresDetails,
            Routes.SalesCartsSearch,
            Routes.SalesCartsNew,
            Routes.SalesCartsDetails,
            Routes.SalesOrdersSearch,
            Routes.SalesOrdersDetails,
            Routes.SalesOrdersPlacedSearch,
            Routes.SalesOrdersPlacedDetails,
            Routes.SalesOrdersProcessingSearch,
            Routes.SalesOrdersProcessingDetails,
            Routes.SalesOrdersShippedSearch,
            Routes.SalesOrdersReadySearch,
            Routes.SalesOrdersReadyDetails,
            Routes.SalesOrdersShippedDetails,
            Routes.FinanceDocumentType1,
            Routes.FinanceDocumentType2,
            Routes.FinanceDocumentType3,
            Routes.FinanceDocumentType4,

            Routes.FinanceDocumentType1Details,
            Routes.FinanceDocumentType2Details,
            Routes.FinanceDocumentType3Details,
            Routes.FinanceDocumentType4Details,
            Routes.FinanceDocumentTypeGeneralDetails,
            Routes.ReportsFinancialDocuments,
            Routes.ReportsFinancialStatementsPerPartner,
            Routes.ReportsFinancialStatementsPerAccount,
            Routes.ReportsSalesOrders,
            Routes.ReportsInvoices,
            Routes.ReportsTrialBalance,
            Routes.StoreZonesSearch,
            Routes.MarketingNotificationCampaign,
            Routes.TopUpPartnersSearch,
            Routes.WalletTransaction,
            Routes.WalletTransactionDetails,
            Routes.TopUpPartnersNew,
            Routes.TopUpPartnersDetails,
            Routes.TopUpTransactionsSearch,
            Routes.TopUpTransactionsDetails,
            Routes.SettingsCategory,
            Routes.SettingsCategoryDetials,
            Routes.SettingsCategoryNew,
            Routes.SettingsBanner,
            Routes.SettingsBannerNew,
            Routes.SettingsBannerDetials,
            Routes.ImportHelper,
            Routes.ImportHelperDetails,

            // Routes.SalesOfferSearch,
            // Routes.SalesOfferNew,
            // Routes.SalesOfferDetails,
            Routes.PaymentPartnersSearch,
            Routes.PaymentPartnersNew,
            Routes.PaymentPartnersDetails,
            Routes.PaymentTransactionsSearch,
            Routes.PaymentTransactionDetails,
            Routes.PaymentOrdersSearch,
            Routes.PaymentOrderDetails,
            Routes.BrokenOrdersSearch,
            Routes.WrongOrderDetails,
            Routes.DeliveredOrdersSearch,
            Routes.PlacedOrdersSearch,
            Routes.ShippedOrdersSearch,
            Routes.DeliveryPartnersSearch,
            Routes.DeliveryPartnersNew,
            Routes.DeliveryPartnersDetails,
            Routes.PartnerConnectionsSearch
        ],
    }

    private readonly manager: UserPermissions = {
        actions: [
            TawreedActionName.ReportsExport,
            TawreedActionName.DashboardCardsSales,
            TawreedActionName.DashboardCardsOrdersPlaced,
            TawreedActionName.DashboardCardsOrdersProcessing,
            TawreedActionName.DashboardCardsOrdersShipped,
            TawreedActionName.DashboardCardsOrdersReturnShipped,
            TawreedActionName.DashboardCardsOrdersReadyForDelivery,
            TawreedActionName.DashboardCardsPartners,
            TawreedActionName.DashboardChartOrders,
            TawreedActionName.DashboardChartOrdersTotals,
            TawreedActionName.DashboardChartPartners,
            TawreedActionName.DashboardChartOrdersPerZone,
            TawreedActionName.DashboardChartOrdersPerState,
            TawreedActionName.ProductsCreate,
            TawreedActionName.PartnerInformation,
            TawreedActionName.ProductsDetails,
            TawreedActionName.ProductsDelete,
            TawreedActionName.ProductsSubmit,
            TawreedActionName.StoreProductsCreate,
            TawreedActionName.StoreProductsDetails,
            TawreedActionName.StoreProductsDelete,
            TawreedActionName.StoreProductsSubmit,
            TawreedActionName.StoreProductsStageImport,
            TawreedActionName.StoreProductsStageApprove,
            TawreedActionName.StoreProductsStageReject,
            TawreedActionName.StoreProductsDownloadTemplate,
            TawreedActionName.StoreProductsActivate,
            TawreedActionName.StoreProductsDeactivate,
            TawreedActionName.UsersCreate,
            TawreedActionName.UsersDetails,
            TawreedActionName.UsersDelete,
            TawreedActionName.UsersSubmit,
            TawreedActionName.UsersChangePassword,
            TawreedActionName.CustomerPartnerCreate,
            TawreedActionName.StorePartnerDetails,
            TawreedActionName.CustomerPartnerDetails,
            TawreedActionName.PartnersDelete,
            TawreedActionName.CustomerPartnerSubmit,
            TawreedActionName.PartnersCustomersVerify,
            TawreedActionName.PartnersCustomersCreateWallet,
            TawreedActionName.PartnersCustomersUploadLicenseContentId,
            TawreedActionName.PartnersCustomersUploadTadeRegisterContentId,
            TawreedActionName.PartnersFilesUpload,
            TawreedActionName.PartnersFilesDownload,
            TawreedActionName.PartnersStoresVerify,
            TawreedActionName.PartnersCustomersUploadLogo,
            TawreedActionName.CartsCreate,
            TawreedActionName.CartsDetails,
            TawreedActionName.CartsUpdate,
            TawreedActionName.CartsStoreCartDelete,
            TawreedActionName.CartsStoreCartItemDelete,
            TawreedActionName.OrdersDetails,
            TawreedActionName.OrdersConfirm,
            TawreedActionName.OrdersShip,
            TawreedActionName.OrdersDeliver,
            TawreedActionName.OrdersReturn,
            TawreedActionName.OrdersPrintStore,
            TawreedActionName.OrdersPrintCustomer,
            TawreedActionName.OrdersEditQuantity,
            TawreedActionName.OrderEditDiscountValue,
            TawreedActionName.OrdersEditBonus,
            TawreedActionName.OrdersEditRetailPrice,
            TawreedActionName.OrdersEditDiscountPercent,
            TawreedActionName.OrdersPartnersDetails,
            TawreedActionName.OrdersPartnersShipAll,
            TawreedActionName.OrdersPartnersReturnAll,
            TawreedActionName.OrdersPartnersPrintAll,
            TawreedActionName.OrdersPartnersPrint,
            TawreedActionName.OrdersReadyForDelivery,
            TawreedActionName.OrdersReturnCreate,
            TawreedActionName.ReportsGenerate,
            TawreedActionName.ZonesCreate,
            TawreedActionName.ZonesDetails,
            TawreedActionName.ZonesDelete,
            TawreedActionName.ZonesSubmit,
            TawreedActionName.MarketingCampaign,
            TawreedActionName.TopUpPartnersCleanup,
            TawreedActionName.PaymentPartnersCreate,
            TawreedActionName.PaymentPartnersDetails,

            TawreedActionName.SalesOfferDetails,
            TawreedActionName.SalesOfferCreate,
            TawreedActionName.SalesOfferSubmit,
            TawreedActionName.SalesOfferDelete,
            TawreedActionName.SalesOfferActivate,
            TawreedActionName.SalesOfferDeactivate,
            
            TawreedActionName.BannerNew,
            TawreedActionName.BannerDetails,
            TawreedActionName.BannerSubmit,
            TawreedActionName.BannerDelete,
            TawreedActionName.PartnerConnectionSetClassification,
            TawreedActionName.PartnerConnectionRemoveClassification,

        ],
        routes: [
            Routes.Login,
            Routes.Notifications,
            Routes.TawreedError,
            Routes.Dashboard,
            Routes.ProductsSearch,
            Routes.ProductsNew,
            Routes.ProductsDetails,
            Routes.UnverifiedProductsDetails,
            Routes.UnverifiedProductsSearch,
            Routes.ImportStoreProductsSearch,
            Routes.StoreProductsSearch,
            Routes.SellerStoreProductSearch,
            Routes.StoreProductsNew,
            Routes.StoreProductsDetails,
            Routes.UsersSearch,
            Routes.UsersNew,
            Routes.UsersDetails,
            Routes.PartnersCustomersSearch,
            Routes.PartnersCustomersNew,
            Routes.PartnersCustomersDetails,
            Routes.PartnersStoresSearch,
            Routes.PartnersStoresNew,
            Routes.PartnersStoresDetails,
            Routes.SalesCartsSearch,
            Routes.SalesCartsNew,
            Routes.SalesCartsDetails,
            Routes.SalesOrdersSearch,
            Routes.SalesOrdersDetails,
            Routes.SalesOrdersPlacedSearch,
            Routes.SalesOrdersPlacedDetails,
            Routes.SalesOrdersProcessingSearch,
            Routes.SalesOrdersProcessingDetails,
            Routes.SalesOrdersShippedSearch,
            Routes.SalesOrdersReadySearch,
            Routes.SalesOrdersReadyDetails,
            Routes.SalesOrdersShippedDetails,
            Routes.ReportsFinancialDocuments,
            Routes.ReportsFinancialStatementsPerPartner,
            Routes.ReportsFinancialStatementsPerAccount,
            Routes.ReportsSalesOrders,
            Routes.ReportsInvoices,
            Routes.ReportsTrialBalance,
            Routes.MarketingNotificationCampaign,
            // Routes.SalesOfferSearch,
            // Routes.SalesOfferNew,
            // Routes.SalesOfferDetails,
            Routes.ImportHelper,
            Routes.ImportHelperDetails,
            
            Routes.SettingsBanner,
            Routes.SettingsBannerNew,
            Routes.SettingsBannerDetials,
            Routes.PartnerConnectionsSearch
        ],
    }

    private readonly sales: UserPermissions = {
        actions: [
            TawreedActionName.ReportsExport,
            TawreedActionName.DashboardCardsSales,
            TawreedActionName.DashboardCardsOrdersPlaced,
            TawreedActionName.DashboardCardsOrdersProcessing,
            TawreedActionName.DashboardCardsOrdersShipped,
            TawreedActionName.DashboardCardsOrdersReturnShipped,
            TawreedActionName.DashboardCardsOrdersReadyForDelivery,
            TawreedActionName.DashboardCardsPartners,
            TawreedActionName.DashboardChartOrders,
            TawreedActionName.DashboardChartOrdersTotals,
            TawreedActionName.DashboardChartPartners,
            TawreedActionName.DashboardChartOrdersPerZone,
            TawreedActionName.DashboardChartOrdersPerState,
            TawreedActionName.StorePartnerDetails,
            TawreedActionName.CustomerPartnerDetails,
            TawreedActionName.PartnersFilesDownload,
            
            TawreedActionName.CartsStoreCartDelete,
            TawreedActionName.CartsStoreCartItemDelete,
            TawreedActionName.OrdersDetails,
            TawreedActionName.OrdersConfirm,
            TawreedActionName.OrdersShip,
            TawreedActionName.OrdersDeliver,
            TawreedActionName.OrdersCancel,
            TawreedActionName.OrdersReturn,
            TawreedActionName.OrdersPrintStore,
            TawreedActionName.OrdersPrintCustomer,
            TawreedActionName.OrdersEditQuantity,
            TawreedActionName.OrdersPartnersDetails,
            TawreedActionName.OrdersPartnersShipAll,
            TawreedActionName.OrdersPartnersReturnAll,
            TawreedActionName.OrdersPartnersPrintAll,
            TawreedActionName.OrdersPartnersPrint,
            TawreedActionName.OrdersReadyForDelivery,
            TawreedActionName.OrdersReturnCreate,
            TawreedActionName.ReportsGenerate,
            TawreedActionName.MarketingCampaign,

            TawreedActionName.UsersChangePassword,
            TawreedActionName.PaymentPartnersDetails,
            TawreedActionName.ImpoerHelperDetails,
            TawreedActionName.ImportUploadTask,
            TawreedActionName.StoreProductsStageImport,
            TawreedActionName.StoreProductsCreate,
            TawreedActionName.StoreProductsDetails,
            TawreedActionName.StoreProductsDelete,
            TawreedActionName.StoreProductsSubmit,
            
            TawreedActionName.SalesOfferDetails,
            TawreedActionName.SalesOfferCreate,
            TawreedActionName.SalesOfferSubmit,
            TawreedActionName.SalesOfferDelete,
            TawreedActionName.SalesOfferActivate,
            TawreedActionName.SalesOfferDeactivate,
            TawreedActionName.BannerNew,
            TawreedActionName.BannerDetails,
            TawreedActionName.BannerSubmit,
            TawreedActionName.BannerDelete,
        ],
        routes: [
            Routes.Login,
            Routes.Notifications,
            Routes.TawreedError,
            Routes.Dashboard,

            Routes.ProductsSearch,
            Routes.ProductsDetails,
            Routes.UnverifiedProductsSearch,
            Routes.UnverifiedProductsDetails,
            Routes.SellerStoreProductSearch,

            Routes.PartnersCustomersSearch,
            Routes.PartnersCustomersDetails,

            Routes.StoreProductsSearch,
            Routes.StoreProductsNew,
            Routes.StoreProductsDetails,
            
            Routes.PartnersStoresSearch,
            Routes.PartnersStoresDetails,

           
            Routes.SalesOrdersSearch,
            Routes.SalesOrdersDetails,
            Routes.SalesOrdersPlacedSearch,
            Routes.SalesOrdersPlacedDetails,
            Routes.SalesOrdersProcessingSearch,
            Routes.SalesOrdersProcessingDetails,
            Routes.SalesOrdersShippedSearch,
            Routes.SalesOrdersReadySearch,
            Routes.SalesOrdersReadyDetails,
            Routes.SalesOrdersShippedDetails,


            Routes.MarketingNotificationCampaign,
            Routes.UsersDetails,
            // Routes.SalesOfferSearch,
            // Routes.SalesOfferDetails,
            // Routes.SalesOfferNew,
            
            Routes.SettingsBanner,
            Routes.SettingsBannerNew,
            Routes.SettingsBannerDetials,
            
            Routes.ReportsSalesOrders,
        ],
    }

    private readonly delivery: UserPermissions = {
        actions: [
            TawreedActionName.ReportsExport,
            TawreedActionName.StorePartnerDetails,
            TawreedActionName.CustomerPartnerDetails,
            TawreedActionName.PartnersFilesDownload,
            TawreedActionName.OrdersDetails,
            TawreedActionName.OrdersShip,
            TawreedActionName.OrdersPartnersShipAll,
            TawreedActionName.OrdersPrintStore,
            TawreedActionName.OrdersPrintCustomer,
            TawreedActionName.OrdersPartnersDetails,
            TawreedActionName.OrdersPartnersPrintAll,
            TawreedActionName.OrdersPartnersPrint,
            TawreedActionName.OrdersDeliver,
            TawreedActionName.DashboardCardsOrdersShipped,
            TawreedActionName.DashboardCardsOrdersReadyForDelivery,

            TawreedActionName.UsersChangePassword,
            TawreedActionName.ReportsExport,
            TawreedActionName.ReportsGenerate,


        ],
        routes: [
            Routes.Login,
            Routes.Notifications,
            Routes.TawreedError,

            Routes.Dashboard,
            Routes.PartnersStoresSearch,
            Routes.PartnersStoresDetails,

            Routes.PartnersCustomersDetails,

            Routes.SalesOrdersSearch,
            Routes.SalesOrdersDetails,

            Routes.SalesOrdersShippedSearch,
            Routes.SalesOrdersShippedDetails,

            Routes.SalesOrdersReadySearch,
            Routes.SalesOrdersReadyDetails,

            Routes.ReportsSalesOrders,

            Routes.UsersDetails



        ],
    }

    private readonly store: UserPermissions = {
        actions: [
            TawreedActionName.ReportsExport,
            TawreedActionName.ReportsGenerate,


            TawreedActionName.DashboardCardsSales,
            TawreedActionName.DashboardCardsOrdersPlaced,
            TawreedActionName.DashboardCardsOrdersProcessing,
            TawreedActionName.DashboardCardsOrdersShipped,
            TawreedActionName.DashboardCardsOrdersReturnShipped,
            TawreedActionName.DashboardChartOrdersTotals,
            TawreedActionName.DashboardCardBalance,
            TawreedActionName.DashboardCardsOrdersReadyForDelivery,
            TawreedActionName.DashboardChartOrdersPerZone,
            TawreedActionName.DashboardChartOrdersPerState,
            TawreedActionName.DashboardChartOrders,
            
            TawreedActionName.StorePartnerDetails,
            TawreedActionName.CustomerPartnerDetails,

            TawreedActionName.PartnerInformation,
            TawreedActionName.ProductsDetails,


            TawreedActionName.StoreProductsCreate,
            TawreedActionName.StoreProductsDetails,
            TawreedActionName.StoreProductsDelete,
            TawreedActionName.StoreProductsSubmit,
            TawreedActionName.StoreProductsStageImport,
            TawreedActionName.StoreProductsStageApprove,
            TawreedActionName.StoreProductsStageReject,
            TawreedActionName.StoreProductsDownloadTemplate,
            TawreedActionName.StoreProductsActivate,
            TawreedActionName.StoreProductsDeactivate,

            TawreedActionName.CartsCreate,
            TawreedActionName.CartsDetails,
            TawreedActionName.CartsUpdate,
            TawreedActionName.CartsStoreCartDelete,
            TawreedActionName.CartsStoreCartItemDelete,
            TawreedActionName.OrdersPartnersDetails,
            TawreedActionName.OrdersDetails,
            TawreedActionName.OrdersConfirm,
            TawreedActionName.OrdersShip,
            TawreedActionName.OrdersDeliver,
            TawreedActionName.OrdersCancel,
            TawreedActionName.OrdersReturn,
            TawreedActionName.OrdersPrintStore,
            TawreedActionName.OrdersPrintCustomer,
            TawreedActionName.OrdersEditQuantity,
            TawreedActionName.OrderEditDiscountValue,
            TawreedActionName.OrdersEditBonus,
            TawreedActionName.OrdersEditRetailPrice,
            TawreedActionName.OrdersEditDiscountPercent,
            TawreedActionName.OrdersPartnersDetails,
            TawreedActionName.OrdersPartnersShipAll,
            TawreedActionName.OrdersPartnersReturnAll,
            TawreedActionName.OrdersPartnersPrintAll,
            TawreedActionName.OrdersPartnersPrint,
            TawreedActionName.OrdersReadyForDelivery,
            TawreedActionName.OrdersReturnCreate,

            TawreedActionName.PartnersFilesDownload,

            TawreedActionName.UsersCreate,
            TawreedActionName.UsersDetails,
            //TawreedActionName.UsersDelete,
            TawreedActionName.UsersSubmit,
            TawreedActionName.UsersChangePassword,
            TawreedActionName.SalesOfferDetails,
            TawreedActionName.SalesOfferCreate,
            TawreedActionName.SalesOfferSubmit,
            TawreedActionName.SalesOfferDelete,
            TawreedActionName.SalesOfferActivate,
            TawreedActionName.SalesOfferDeactivate,

            TawreedActionName.PartnerConnectionSetClassification,
            TawreedActionName.PartnerConnectionRemoveClassification,

        ],
        routes: [
            Routes.Login,
            Routes.ProductsDetails,
            Routes.Notifications,
            Routes.TawreedError,
            Routes.Dashboard,
            Routes.PartnersCustomersDetails,
            Routes.PartnersStoresDetails,

            Routes.ImportStoreProductsSearch,
            Routes.StoreProductsSearch,
            Routes.SellerStoreProductSearch,
            Routes.StoreProductsNew,
            Routes.StoreProductsDetails,

            Routes.UnverifiedProductsDetails,

            Routes.SalesCartsSearch,
            Routes.SalesCartsNew,
            Routes.SalesCartsDetails,

            Routes.SalesOrdersSearch,
            Routes.SalesOrdersDetails,
            Routes.SalesOrdersPlacedSearch,
            Routes.SalesOrdersPlacedDetails,
            Routes.SalesOrdersProcessingSearch,
            Routes.SalesOrdersProcessingDetails,
            Routes.SalesOrdersShippedSearch,
            Routes.SalesOrdersReadySearch,
            Routes.SalesOrdersReadyDetails,
            Routes.SalesOrdersShippedDetails,

            Routes.ReportsFinancialStatementsPerPartner,
            Routes.ReportsSalesOrders,
            Routes.ReportsInvoices,
            Routes.UsersDetails,
            Routes.UsersSearch,
            Routes.UsersNew,

            // Routes.SalesOfferSearch,
            // Routes.SalesOfferDetails,
            // Routes.SalesOfferNew,
            
            Routes.ImportHelper,
            Routes.ImportHelperDetails,
            Routes.PartnerConnectionsSearch
        ],
    }
    private readonly salesMan: UserPermissions = {
        actions: [
            TawreedActionName.ReportsExport,
            TawreedActionName.ReportsGenerate,
            TawreedActionName.UsersSubmit,
            TawreedActionName.FinanceDocumentsCreate,

            //TawreedActionName.DashboardCardsSales,
            TawreedActionName.DashboardCardsOrdersPlaced,
            TawreedActionName.DashboardCardsOrdersProcessing,
            // TawreedActionName.DashboardCardsOrdersShipped,
            // TawreedActionName.DashboardCardsOrdersReturnShipped,
            // TawreedActionName.DashboardChartOrdersTotals,
            // TawreedActionName.DashboardCardBalance,
            // TawreedActionName.DashboardCardsOrdersReadyForDelivery,
            // TawreedActionName.DashboardChartOrdersPerZone,
            // TawreedActionName.DashboardChartOrders,

            // TawreedActionName.PartnersDetails,

            // TawreedActionName.PartnerInformation,
            TawreedActionName.ProductsDetails,


            TawreedActionName.StoreProductsDetails,
            TawreedActionName.StoreProductsStageImport,
            TawreedActionName.StoreProductsStageApprove,
            TawreedActionName.StoreProductsStageReject,

            TawreedActionName.CartsCreate,
            TawreedActionName.CartsDetails,
            TawreedActionName.CartsUpdate,
            TawreedActionName.CartsStoreCartDelete,
            TawreedActionName.CartsStoreCartItemDelete,

            TawreedActionName.OrdersPartnersDetails,
            TawreedActionName.OrdersDetails,
            TawreedActionName.OrdersConfirm,
            TawreedActionName.OrdersShip,
            TawreedActionName.OrdersDeliver,
            TawreedActionName.OrdersCancel,
            TawreedActionName.OrdersReturn,
            TawreedActionName.OrdersPrintStore,
            TawreedActionName.OrdersPrintCustomer,
            TawreedActionName.OrdersEditQuantity,
            TawreedActionName.OrderEditDiscountValue,
            TawreedActionName.OrdersEditBonus,
            TawreedActionName.OrdersEditRetailPrice,
            TawreedActionName.OrdersEditDiscountPercent,
            TawreedActionName.OrdersPartnersDetails,
            TawreedActionName.OrdersPartnersShipAll,
            TawreedActionName.OrdersPartnersReturnAll,
            TawreedActionName.OrdersPartnersPrintAll,
            TawreedActionName.OrdersPartnersPrint,
            TawreedActionName.OrdersReadyForDelivery,
            TawreedActionName.OrdersReturnCreate,

            TawreedActionName.PartnersFilesDownload,

            TawreedActionName.UsersChangePassword,
            TawreedActionName.UsersCreate,
            TawreedActionName.UsersDetails,
            //TawreedActionName.UsersDelete,
            TawreedActionName.UsersSubmit,
            TawreedActionName.StorePartnerSubmit



        ],
        routes: [
            Routes.Login,
            Routes.Notifications,
            Routes.TawreedError,
            Routes.Dashboard,
            Routes.PartnersCustomersDetails,
            Routes.PartnersStoresDetails,

            Routes.StoreProductsSearch,
            Routes.SellerStoreProductSearch,
            Routes.StoreProductsDetails,
            Routes.ProductsDetails,

            Routes.UnverifiedProductsDetails,

            Routes.SalesCartsSearch,
            Routes.SalesCartsNew,
            Routes.SalesCartsDetails,

            Routes.SalesOrdersSearch,
            Routes.SalesOrdersDetails,
            Routes.SalesOrdersPlacedSearch,
            Routes.SalesOrdersPlacedDetails,
            Routes.SalesOrdersProcessingSearch,
            Routes.SalesOrdersProcessingDetails,
            Routes.SalesOrdersShippedSearch,
            Routes.SalesOrdersReadySearch,
            Routes.SalesOrdersReadyDetails,
            Routes.SalesOrdersShippedDetails,
            Routes.PartnerConnectionsSearch,

            //Routes.ReportsFinancialStatementsPerPartner,
            Routes.ReportsSalesOrders,
            //Routes.ReportsInvoices,
            Routes.UsersDetails,
            //Routes.UsersSearch,
            //Routes.UsersNew,
            //Routes.PartnerConnectionsSearch
            // Routes.SalesOfferSearch,
            // Routes.SalesOfferNew,
            // Routes.SalesOfferDetails,


        ],
    }
    private readonly customer: UserPermissions = {
        actions: [
            TawreedActionName.ReportsExport,
            TawreedActionName.ReportsGenerate,

            TawreedActionName.DashboardCardsOrdersPlaced,
            TawreedActionName.DashboardCardsOrdersProcessing,
            TawreedActionName.DashboardCardsOrdersShipped,
            TawreedActionName.DashboardCardsOrdersReadyForDelivery,
            TawreedActionName.DashboardCardsOrdersReturnShipped,
            TawreedActionName.DashboardCardBalance,
            TawreedActionName.PartnerInformation,
            TawreedActionName.PartnersFilesDownload,
            TawreedActionName.DashboardChartPartners,

            TawreedActionName.StorePartnerDetails,
            TawreedActionName.CustomerPartnerDetails,


            TawreedActionName.ProductsDetails,
            TawreedActionName.CartsCreate,
            TawreedActionName.CartsDetails,
            TawreedActionName.CartsUpdate,
            TawreedActionName.CartsStoreCartDelete,
            TawreedActionName.CartsStoreCartItemDelete,
            TawreedActionName.DeleteMyAccount,

            TawreedActionName.OrdersDetails,
            TawreedActionName.OrdersPartnersDetails,
            TawreedActionName.OrdersPrintCustomer,
            TawreedActionName.OrdersEditQuantity,
            TawreedActionName.OrdersEditRetailPrice,
            TawreedActionName.OrderEditDiscountValue,
            TawreedActionName.OrdersEditBonus,
            TawreedActionName.OrdersEditDiscountPercent,


            TawreedActionName.UsersChangePassword,

        ],
        routes: [
            Routes.Login,
            Routes.Notifications,
            Routes.TawreedError,
            Routes.Dashboard,
            Routes.ProductsDetails,
            // Routes.PartnersStoresSearch,
            // Routes.PartnersStoresDetails,

            Routes.UnverifiedProductsDetails,
            Routes.SalesCartsSearch,
            Routes.SalesCartsDetails,
            Routes.SalesOrdersDetails,
            Routes.SalesOrdersPlacedSearch,
            Routes.SalesOrdersPlacedDetails,
            Routes.SalesOrdersProcessingSearch,
            Routes.SalesOrdersProcessingDetails,
            Routes.SalesOrdersShippedSearch,
            Routes.SalesOrdersReadySearch,
            Routes.SalesOrdersReadyDetails,
            Routes.SalesOrdersShippedDetails,
            Routes.ReportsSalesOrders,
            Routes.UsersDetails,
            Routes.PartnersCustomersDetails,
            Routes.SalesOrdersSearch,
        ],
    }

    private readonly topup: UserPermissions = {
        actions: [
            TawreedActionName.PartnersFilesDownload,
            TawreedActionName.ReportsGenerate,
            TawreedActionName.UsersChangePassword,

        ],
        routes: [
            Routes.Login,
            Routes.Notifications,
            Routes.TawreedError,
            Routes.UsersDetails

        ],
    }

    private readonly finance: UserPermissions = {
        actions: [
            TawreedActionName.TopUpTransactionsDetails,
            TawreedActionName.TopUpPartnersDetails,
            TawreedActionName.StorePartnerDetails,
            TawreedActionName.CustomerPartnerDetails,
            TawreedActionName.OrdersDetails,
            TawreedActionName.OrdersPrintStore,
            TawreedActionName.OrdersPrintCustomer,
            TawreedActionName.OrdersPartnersDetails,
            TawreedActionName.OrdersPartnersPrintAll,
            TawreedActionName.OrdersPartnersPrint,
            TawreedActionName.ReportsExport,
            TawreedActionName.ReportsGenerate,
            TawreedActionName.FinanceDocumentsCreate,
            TawreedActionName.DashboardCardsSales,
            TawreedActionName.DashboardCardsOrdersReturnShipped,
            TawreedActionName.PartnersFilesDownload,
            TawreedActionName.UsersChangePassword,
            TawreedActionName.WalletTransactionDetails,
            TawreedActionName.PaymentPartnersDetails,
            
            TawreedActionName.DeliveryPartnersDetails,
            TawreedActionName.SalesOfferDetails,
            TawreedActionName.StoreProductsDetails
        ],
        routes: [
            Routes.Login,
            Routes.Notifications,
            Routes.TawreedError,
            Routes.Dashboard,

            Routes.ProductsSearch,
            Routes.ProductsDetails,
            Routes.UnverifiedProductsSearch,
            Routes.UnverifiedProductsDetails,
            Routes.PartnersStoresSearch,
            Routes.PartnersStoresDetails,

            Routes.PartnersCustomersDetails,

            Routes.TopUpPartnersSearch,
            Routes.TopUpPartnersDetails,

            Routes.DeliveryPartnersSearch,
            Routes.DeliveryPartnersDetails,

            Routes.TopUpTransactionsSearch,
            Routes.TopUpTransactionsDetails,

            Routes.WalletTransaction,
            Routes.WalletTransactionDetails,
            Routes.StoreProductsSearch,


            Routes.FinanceDocumentType1,
            Routes.FinanceDocumentType2,
            Routes.FinanceDocumentType3,
            Routes.FinanceDocumentType4,

            Routes.FinanceDocumentType1Details,
            Routes.FinanceDocumentType2Details,
            Routes.FinanceDocumentType3Details,
            Routes.FinanceDocumentType4Details,
            Routes.FinanceDocumentTypeGeneralDetails,
            Routes.ReportsFinancialDocuments,
            Routes.ReportsFinancialStatementsPerPartner,
            Routes.ReportsFinancialStatementsPerAccount,
            Routes.ReportsSalesOrders,
            Routes.ReportsInvoices,
            Routes.ReportsTrialBalance,
            Routes.UsersDetails,
            Routes.PaymentOrdersSearch,
            Routes.PaymentOrderDetails,
            // Routes.SalesOfferSearch,
            // Routes.SalesOfferDetails,
            // Routes.StoreProductsDetails
        ],
    }
    
    private readonly walletAdmin: UserPermissions = {
        actions: [
            TawreedActionName.ReportsExport,

            TawreedActionName.PartnersFilesDownload,
            TawreedActionName.ReportsGenerate,
            TawreedActionName.UsersChangePassword,

        ],
        routes: [
            Routes.Login,
            Routes.Notifications,
            Routes.TawreedError,
            Routes.UsersDetails
        ],
    };
    
    private readonly catManager: UserPermissions = {
        actions: [
            TawreedActionName.ProductsCreate,
            TawreedActionName.ProductsDetails,
            TawreedActionName.ProductsDelete,
            TawreedActionName.ProductsSubmit,
            TawreedActionName.StoreProductsCreate,
            TawreedActionName.StoreProductsDetails,
            TawreedActionName.StoreProductsDelete,
            TawreedActionName.StoreProductsSubmit,
            TawreedActionName.StoreProductsStageImport,
            TawreedActionName.StoreProductsStageApprove,
            TawreedActionName.StoreProductsStageReject,
            TawreedActionName.StoreProductsDownloadTemplate,
            TawreedActionName.StoreProductsActivate,
            TawreedActionName.StoreProductsDeactivate,
            TawreedActionName.PartnersFilesDownload,
            TawreedActionName.UsersChangePassword,
            TawreedActionName.ImpoerHelperDetails,
            TawreedActionName.ImportUploadTask,

            TawreedActionName.SalesOfferDetails,
            TawreedActionName.SalesOfferCreate,
            TawreedActionName.SalesOfferSubmit,
            TawreedActionName.SalesOfferDelete,
            TawreedActionName.SalesOfferActivate,
            TawreedActionName.SalesOfferDeactivate,
            TawreedActionName.CartsCreate,
            TawreedActionName.CartsDetails,
            TawreedActionName.CartsUpdate,
            TawreedActionName.OrdersDetails,
            TawreedActionName.OrdersPartnersDetails,
            TawreedActionName.OrdersPrintCustomer,
            TawreedActionName.OrdersEditQuantity,
            TawreedActionName.OrdersEditRetailPrice,
            TawreedActionName.OrderEditDiscountValue,
            TawreedActionName.OrdersEditBonus,
            TawreedActionName.OrdersEditDiscountPercent,

        ],
        routes: [
            Routes.Login,
            Routes.Notifications,
            Routes.TawreedError,
            Routes.ProductsSearch,
            Routes.ProductsNew,
            Routes.ProductsDetails,
            Routes.UnverifiedProductsDetails,
            Routes.UnverifiedProductsSearch,
            Routes.ImportStoreProductsSearch,
            Routes.StoreProductsSearch,
            Routes.SellerStoreProductSearch,
            Routes.StoreProductsNew,
            Routes.StoreProductsDetails,
            Routes.UsersDetails,
            Routes.ImportHelper,
            Routes.ImportHelperDetails,
            // Routes.SalesOfferSearch,
            // Routes.SalesOfferNew,
            // Routes.SalesOfferDetails,

            Routes.SalesCartsSearch,
            Routes.SalesCartsNew,
            Routes.SalesCartsDetails,
            Routes.SalesOrdersSearch,
            Routes.SalesOrdersDetails,
            Routes.SalesOrdersPlacedSearch,
            Routes.SalesOrdersPlacedDetails,
            Routes.SalesOrdersProcessingSearch,
            Routes.SalesOrdersProcessingDetails,
            Routes.SalesOrdersShippedSearch,
            Routes.SalesOrdersReadySearch,
            Routes.SalesOrdersReadyDetails,
            Routes.SalesOrdersShippedDetails,
        ],
    };

    private readonly storeDelivery: UserPermissions = {
        actions: [
            TawreedActionName.DashboardCardsOrdersShipped,
            TawreedActionName.DashboardCardsOrdersReadyForDelivery,
            TawreedActionName.ReportsExport,

            TawreedActionName.PartnersFilesDownload,

            TawreedActionName.CustomerPartnerDetails,
            TawreedActionName.OrdersDetails,
            TawreedActionName.OrdersShip,
            TawreedActionName.OrdersPartnersShipAll,
            TawreedActionName.OrdersPrintStore,
            TawreedActionName.OrdersPrintCustomer,
            TawreedActionName.OrdersPartnersDetails,
            TawreedActionName.OrdersPartnersPrintAll,
            TawreedActionName.OrdersPartnersPrint,
            TawreedActionName.OrdersDeliver,
            TawreedActionName.OrdersCancel,
            TawreedActionName.UsersChangePassword,
            TawreedActionName.ReportsGenerate,
            TawreedActionName.ReportsExport

        ],
        routes: [
            Routes.Login,
            Routes.Notifications,
            Routes.TawreedError,
            Routes.Dashboard,


            Routes.SalesOrdersSearch,
            Routes.SalesOrdersDetails,


            Routes.SalesOrdersShippedSearch,
            Routes.SalesOrdersShippedDetails,

            Routes.SalesOrdersReadySearch,
            Routes.SalesOrdersReadyDetails,


            Routes.UsersDetails,

            Routes.ReportsSalesOrders,


        ],
    }



    public getPermissions(role: TawreedRoleName): Promise<UserPermissions> {
        return new Promise<UserPermissions>((resolve, reject) => {

            switch (role) {
                case "ADMIN":
                    return resolve(this.administrator);
                case "SYS_ADMIN":
                    return resolve(this.sysAdmin);
                case "WALLET_ADMIN":
                    return resolve(this.walletAdmin);
                case "CATMANAGER":
                    return resolve(this.catManager);
                case "STORE_DELIVERY":
                    return resolve(this.storeDelivery);
                case "MANAGER":
                    return resolve(this.manager);
                case "SALES":
                    return resolve(this.sales);
                case "DELIVERY":
                    return resolve(this.delivery);
                case "STORE_ADMIN":
                    return resolve(this.store);
                case "CUSTOMER_ADMIN":
                    return resolve(this.customer);
                case "TOPUP_ADMIN":
                    return resolve(this.topup);
                case "FINANCE":
                    return resolve(this.finance);
                case "STORE_SALES_MAN":
                    return resolve(this.salesMan);
                default:
                    return reject('MSG_ROLENAME_UNKNOWN');
            }
        });
    }
}
