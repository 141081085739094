import React, { FC, useMemo, useState } from "react";
import { PrimeIcons } from "primereact/api";
import { Panel } from "primereact/panel";
import { useHistory, useParams } from "react-router-dom";

import { TawreedAction, TawreedActionName } from "../../../../common/actions";
import { TawreedCalendar, TawreedDropdown, TawreedForm, TawreedFormField, TawreedFormMode, TawreedFormSubmitAction, TawreedFormSubmitCallback, TawreedFormSubmitProps, TawreedInputCustom, TawreedInputFile, TawreedInputNumber, TawreedInputSwitch, TawreedInputText, TawreedMenuDropdown, TawreedNavLink, TawreedSitesDropdown } from "../../../../common/components/form";
import { tawreedTranslationService } from "../../../../common/translations";
import { Routes } from "../../../../router";
import { StatesDropdown } from "../../../states";
import { PartnerStatus, PartnerType } from "../../data";
import { CommissionDto, CommissionService, MappingDto, PartnerDto, PartnersService, StoreProductCategoryDto, StoreZoneDto } from "../../domain";
import { PartnerBusinessTypeDropdown } from "./partner-business-type-dropdown";
import { PriceListServcie } from "../../../catalog/store-products/domain/services/price-list.service";
import { priceListDto } from "../../../catalog/store-products/domain";
import { PriceListForm } from "./price-list-from";
import { ZonesDropdown, ZonesMultiSelect } from "../../../zones";
import { CategoriesMultiSelect } from "../../../catalog/products/presentaion/components";
import { toastService } from "../../../notifications/domain";
import { PartnerFiles } from "./partner-files";

import './image-card.scss';
import { NationalityDropdown } from "../../../users/presentaion/components/nationalityDropDown";
import { GlobalContext } from "../../../../context";
import { CitiesDropdown } from "../../../cities";
import { StoreDeliveryMappingTable } from "./store-delivery-mapping-table";
import { GoogleMapsUtils } from "../../../../helpers/google-maps";
import { CommissionTable } from "./commission-table";

export interface PartnerFormProps {

    type: PartnerType;
}

export const PartnerForm: FC<PartnerFormProps> = ({ type }) => {
    // di
    const { id } = useParams<{ id: string | undefined }>();
    const history = useHistory();
    const service: PartnersService = useMemo(() => new PartnersService(), []);
    const priceListService: PriceListServcie = useMemo(() => new PriceListServcie(), []);
    const commissionService: CommissionService = useMemo(() => new CommissionService(), []);
    const [priceLists, setPriceLists] = useState<Array<priceListDto>>([]);
    const [reload, setReload] = useState<Boolean>(false);
    const [commissions, setCommissions] = useState<Array<CommissionDto>>([]);
    const [mappingItems, setMappingItems] = useState<Array<MappingDto>>([]);

    const [loading, setLoading] = useState<boolean>(false);
    const [mode, setMode] = React.useState<TawreedFormMode>('Create');
    const [data, setData] = useState<PartnerDto>({});
    const [zoneList, setZoneList] = useState<Array<StoreZoneDto>>([]);
    const [storeProductCategoryList, setStoreProductCategoryList] = useState<Array<StoreProductCategoryDto>>([]);

    const [actions, setActions] = useState<TawreedAction[]>([]);
    const { constants, auth: { user } } = React.useContext(GlobalContext);
    const [stateId, setStateId] = useState<number>();

    let disabeld = false
    if (type === PartnerType.Store && !(user?.roleCode === "ADMIN" || user?.roleCode === "SYS_ADMIN")) {
        disabeld = true;
    }
    if (type === PartnerType.Customer &&
        !(user?.roleCode === "ADMIN" || user?.roleCode === "SYS_ADMIN" || user?.roleCode === "MANAGER")) {
        disabeld = true;
    }
    const addMapping = (data: { deliveyId: number, deliveryName: string, value: string }) => {
        let items = mappingItems;
        if (items?.filter(x => x.deliveryId === data.deliveyId).length == 0) {
            items?.push({ deliveryId: data.deliveyId, storeId: Number.parseInt(id!), value: data.value, deliveryName: data.deliveryName });
            setMappingItems([...items])
        }
        else {
            toastService.show({ detail: tawreedTranslationService.translate('lbl_sales_offer_items_exists_error'), severity: 'error' });

        }
    }

    const deleteMapping = async (deliveryId?: number, id?: number | undefined) => {
        try {
            if (id) {
                await service.deleteMapping(id);
                let newItems = mappingItems?.filter(el => el.storeDeliveryMappingId !== id);
                setMappingItems(newItems);
            }
            else {
                let newItems = mappingItems?.filter((el) => el.deliveryId !== deliveryId);
                setMappingItems(newItems);
            }

        } catch (err) {
            console.log(err);
        }
    }

    React.useEffect(() => {
        let mounted = true;
        if (id) {
            if (mounted) {
                setMode('Edit');
                setLoading(true);
            }
            priceListService.getAll().then((res) => {
                setPriceLists(res);
            });
            if (type == PartnerType.Store) {
                service.getStoreMapping(Number.parseInt(id))
                    .then(res2 => {
                        setMappingItems(res2);
                    });
            }
            service.getDetails(Number.parseInt(id))
                .then(res => {
                    setStateId(res.address?.stateId);
                    if (mounted) {
                        setLoading(false);
                        setData(res);
                        setZoneList(res.zoneList ?? []);
                        setStoreProductCategoryList(res.storeProductCategoryList ?? [])
                    }
                })
                .catch(() => {
                    if (mounted) {
                        setLoading(false);
                    }
                })
        } else {
            if (mounted) {
                setMode('Create');
                priceListService.getAll().then((res) => {
                    setPriceLists(res);

                    setData({ active: true, address: { ...data.address, location: GoogleMapsUtils.getDefault() } });
                });
            }
        }
        return () => {
            mounted = false;
        };
        // eslint-disable-next-line
    }, [id, service, priceListService, reload]);

    const saveCommissions = async (partnerId: number,) => {
        for await (const commission of commissions) {
            await commissionService.createOrUpdate({ ...commission, partnerId: partnerId });
        }
    }
    const validateComissionOrderMin = async (minOrderValue: number,) => {
        for await (const commission of commissions) {
            await commissionService.validateComissionOrderMin({ ...commission, minOrderValue: minOrderValue });
        }
    }


    const onCreateOrSave = async (data: PartnerDto, cb: TawreedFormSubmitCallback<PartnerDto>): Promise<void> => {

        if (PartnerType.Store === type && !checkCommissonsList()) {

            toastService.show({ detail: tawreedTranslationService.translate('lbl_commission_error'), severity: 'error' });
            return new Promise<void>((resolve, reject) => {
                reject();
            });
        }
        setLoading(true);
        if (PartnerType.Store) {
            try {
                await validateComissionOrderMin(data.orderMin || 0);
            } catch (err: any) {
                setLoading(false);
                cb(err);
                return;
            }
        }


        return service.createOrUpdate(type, { ...data, zoneList: zoneList, storeProductCategoryList: storeProductCategoryList })
            .then(async (res) => {
                if (PartnerType.Store === type)
                    await saveCommissions(res.partnerId!);
                if (id && mappingItems.length > 0) {
                    service.createOrUpdateStoreMapping(mappingItems).then(async (mappingRes) => {
                        setMappingItems(mappingRes);

                    });
                }
                if (!data.partnerId) {
                    let detailsUrl = '';
                    switch (type) {
                        case PartnerType.Customer:
                            detailsUrl = Routes.PartnersCustomersDetails;
                            break;
                        case PartnerType.Store:
                            detailsUrl = Routes.PartnersStoresDetails;
                            break;
                    }
                    history.replace(detailsUrl.replace(':id', res.partnerId!.toString()));
                }
                else
                    setReload(!reload);
                //console.log("reerere", commissions,commissions.filter(x => x.paymentModeId ===undefined));
                //setCommissions([...commissions.filter(x => x.paymentModeId ===undefined)]);
                setLoading(false);
                setData(res);
                setZoneList(res.zoneList ?? []);
                setStoreProductCategoryList(res.storeProductCategoryList ?? [])
                cb(res);
            })
            .catch(err => {
                setLoading(false);
                cb(err);
            });
    }

    const onSubmit: TawreedFormSubmitProps<PartnerDto> = {
        showButton: true,
        resetOnSubmit: false,
        onSubmit: new TawreedFormSubmitAction<PartnerDto>(
            type === PartnerType.Customer ? TawreedActionName.CustomerPartnerSubmit : TawreedActionName.StorePartnerSubmit, 'lbl_save', PrimeIcons.SAVE, onCreateOrSave),
    };


    React.useEffect(
        () => {
            const onVerify = () => {
                setLoading(true);
                return service.verify(data.partnerId!).then(res => {
                    setLoading(false);
                    setData(res);
                    setZoneList(res.zoneList ?? []);
                    setStoreProductCategoryList(res.storeProductCategoryList ?? [])
                }).catch(err => {
                    setLoading(false);
                    throw err;
                });
            }
            const onCreateWallet = () => {
                setLoading(true);
                return service.createWallet(data.partnerId!).then(res => {
                    setLoading(false);
                    setData(res);
                    setZoneList(res.zoneList ?? []);
                    setStoreProductCategoryList(res.storeProductCategoryList ?? [])
                }).catch(err => {
                    setLoading(false);
                    throw err;
                });
            }

            const next = [
            ];
            if (data && data.partnerId && data.partnerType === PartnerType.Customer && data.status === PartnerStatus.UnVerified) {
                next.push(new TawreedAction(TawreedActionName.PartnersCustomersVerify, 'statefull', 'lbl_verify', PrimeIcons.CHECK_CIRCLE, onVerify, undefined, true));
            }
            setActions(next);
        },
        [data, service, history, type]);


    const toZones = (data: any) => {
        console.log(data);
        return data?.map((el: any) => {
            return {
                stateId: undefined,
                zoneId: el.zoneId,
                zoneName: el.zoneName,
                zoneNameEn: "",
            }
        }) ?? [];
    }

    const toProductCategory = (data: any) => {
        return data?.map((el: any) => {
            if (el.categoryId)
                return el.categoryId;
            else
                return el;
        }) ?? [];
    }

    const toStoreProductCategoryList = (e: Array<number>) => {
        const list: Array<StoreProductCategoryDto> = [];
        e?.map((el: number) => {

            list.push({
                categoryId: el,
            })
            return 0;
        })

        // setData({ ...data, storeProductCategoryList: list });
        setStoreProductCategoryList(list);
    }
    const getMinDate = () => {
        let now = new Date();
        now.setFullYear(now.getFullYear() - 18)
        return now;
    }
    const changeComission = (data: CommissionDto, isInit: boolean) => {
        if (isInit) {
            setCommissions((previous) => [...previous, data]);
        }
        else {
            if (data.priceListId) {
                const idx = commissions.findIndex((el) => el.priceListId === data.priceListId)
                const newCommissions: Array<CommissionDto> = [...commissions];
                if (idx >= 0) {
                    newCommissions[idx] = { ...data };
                    setCommissions(newCommissions)
                }
                else {
                    newCommissions.push(data);
                    setCommissions(newCommissions);
                }
            }
            else {
                const idx = commissions.findIndex((el) => el.paymentModeId === data.paymentModeId)
                const newCommissions: Array<CommissionDto> = [...commissions];
                if (idx >= 0) {
                    newCommissions[idx] = { ...data };
                    setCommissions(newCommissions)
                }
                else {
                    newCommissions.push(data);
                    setCommissions(newCommissions);
                }

            }
        }

    }
    const deleteComission = (paymentModeId: number, id?: number | undefined) => {
        try {

            console.log("newList", paymentModeId, id);
            if (id) {
                var newList = commissions.filter(x => x.commissionId !== id);
                console.log("newList", newList);
                setCommissions([...newList]);
            }
            else {
                var newList = commissions.filter(x => x.paymentModeId !== paymentModeId);
                console.log("newList", newList);
                setCommissions([...newList]);
            }

        } catch (err) {
            console.log(err);
        }
    }
    const checkCommissonsList = () => {
        console.log("commissions", commissions.filter(x => x.priceListId !== null && x.priceListId !== undefined).length);
        if (commissions.filter(x => x.priceListId !== null && x.priceListId !== undefined).length !== priceLists.length) {
            console.log("commissions", commissions);
            return false;
        }
        let ok = true;
        commissions.forEach(el => {
            if (!el.value && el.value !== 0) {

                console.log("commissions", commissions);
                ok = false;
            }
        })
        return ok;
    }

    const checkAccountCustomerStatus = () => {
        if (data && data.partnerId && data.partnerType === PartnerType.Customer && data.status === PartnerStatus.Verified)
            return true;
        return false;

    }

    return (
        <React.Fragment>
            <TawreedForm title={'lbl_partner_' + type}
                dataKey="partnerId" data={data}
                submit={onSubmit}
                loading={loading} mode={mode}
                actions={actions} useBack=".." useReset={true} className="grid form-grid">
                <Panel header={tawreedTranslationService.translate('lbl_general_information')} className="col-12">
                    <div className="grid">
                        {
                            type === PartnerType.Store &&
                            <div className="col-12">
                                <div className="grid">
                                    <TawreedFormField name="logoContent" className="field col-12 md:col-3" title="lbl_partners_logo">
                                        <TawreedInputFile disabled={disabeld} render="form" name="logoContent" accept="image" />
                                    </TawreedFormField>
                                </div>
                            </div>
                        }

                        <TawreedFormField name="businessType" className="field col-12 md:col-6" title="lbl_partners_business_type">
                            <PartnerBusinessTypeDropdown disabled={(id ? true : false) || disabeld} partnerType={type} name="businessType" render="form" rules={{ required: 'msg_field_is_required' }} />
                        </TawreedFormField>
                        <TawreedFormField hide={disabeld} name="active" title="lbl_product_active" className="field-checkbox col-12 md:col-6" flip>
                            <TawreedInputSwitch name="active" render="form" />
                        </TawreedFormField>
                        <TawreedFormField name="partnerName" className="field col-12 md:col-6" title="lbl_partners_partner_name">
                            <TawreedInputText disabled={disabeld} name="partnerName" render="form" rules={{ required: 'msg_field_is_required' }} />
                        </TawreedFormField>
                        {
                            type === PartnerType.Customer &&
                            <React.Fragment>
                                <TawreedFormField name="nationalId" className="field col-12 md:col-6" title="lbl_partners_national_id">
                                    <TawreedInputNumber disabled={disabeld} name="nationalId" render="form" />
                                </TawreedFormField>
                                <TawreedFormField name="establishmentNationalId" className="field col-12 md:col-6" title="lbl_partners_establishmentnationalid">
                                    <TawreedInputNumber disabled={disabeld} name="establishmentNationalId" render="form" rules={{ required: 'msg_field_is_required' }} />
                                </TawreedFormField>
                                <TawreedFormField name="expireDate" title="lbl_partners_licence_expire_date" className="field col-12 md:col-6">
                                    <TawreedCalendar disabled={disabeld} render="form" name="licenceExpireDate" />
                                </TawreedFormField>
                                {id && <TawreedFormField name="walletId" title="lbl_topup_partners_walletid" className="field col-12 md:col-6">
                                    <TawreedInputText disabled={true} name="walletId" render="form" />
                                </TawreedFormField>
                                }
                                {id && <TawreedFormField name="walletStatus" title="lbl_wallet_status" className="field col-12 md:col-6">
                                    <TawreedMenuDropdown disabled={!checkAccountCustomerStatus()} menu="walletStatus" name="walletStatus" render="form" />
                                </TawreedFormField>}
                                <TawreedInputCustom disabled={disabeld} render="form" name="businessType" renderer={(businessType: number) => <PartnerFiles className="col-12" render="form" name="files" partnerType={type} businessType={businessType} disabled={disabeld} />} />
                            </React.Fragment>
                        }
                        {
                            type === PartnerType.Store &&
                            <React.Fragment>

                                <TawreedFormField name="orderMin" className="field col-12 md:col-6" title="lbl_partners_order_min">
                                    <TawreedInputNumber disabled={disabeld} name="orderMin" rules={{ required: 'msg_field_is_required' }} render="form" mode="decimal" />
                                </TawreedFormField>
                                <TawreedFormField hide={disabeld} name="priority" className="field col-12 md:col-6" title="lbl_partners_priority">
                                    <TawreedInputNumber disabled={disabeld} name="priority" rules={{ required: 'msg_field_is_required' }} render="form" min={0} max={100} />
                                </TawreedFormField>
                                <TawreedFormField name="walletId" title="lbl_topup_partners_walletid" className="field col-12 md:col-6">
                                    <TawreedInputText disabled={disabeld} name="walletId" render="form" />
                                </TawreedFormField>
                                <TawreedFormField name="walletStatus" title="lbl_wallet_status" className="field col-12 md:col-6">
                                    <TawreedMenuDropdown disabled={disabeld} menu="walletStatus" name="walletStatus" render="form" />
                                </TawreedFormField>
                                <TawreedFormField name="canShipping" title="lbl_can_shipping" className="field col-12 md:col-6">
                                    <TawreedMenuDropdown disabled={disabeld} menu="canShippingOptions" name="canShipping" render="form" />
                                </TawreedFormField>

                            </React.Fragment>
                        }
                    </div>
                </Panel>
                {type === PartnerType.Customer && !id &&


                    <Panel header={tawreedTranslationService.translate('lbl_users_user')} className="col-12">
                        <div className="grid">
                            <TawreedFormField name="userDto.firstName" className="field col-12 md:col-6" title="lbl_users_first_name">
                                <TawreedInputText disabled={disabeld} name="userDto.firstName" render="form" rules={{ required: 'msg_field_is_required' }} />
                            </TawreedFormField>
                            <TawreedFormField name="userDto.lastName" className="field col-12 md:col-6" title="lbl_users_last_name">
                                <TawreedInputText disabled={disabeld} name="userDto.lastName" render="form" rules={{ required: 'msg_field_is_required' }} />
                            </TawreedFormField>
                            {
                                <React.Fragment>
                                    <TawreedFormField name="userDto.mobileNo" className="field col-12 md:col-6" title="lbl_users_mobile_no">
                                        <div className="p-inputgroup w-full">
                                            <TawreedSitesDropdown disabled={disabeld} name="userDto.phoneCode" rules={{ required: 'msg_field_is_required' }} optionValue="value" render="form" style={{ width: '33%' }} className="" />
                                            <TawreedInputText
                                                disabled={disabeld}
                                                rules={{ required: 'msg_field_is_required' }} render="form"
                                                name="userDto.mobileNo"
                                                keyfilter="int"
                                                maxLength={11}
                                                className="inputfield" style={{ width: '66%' }} />
                                        </div>
                                    </TawreedFormField>

                                </React.Fragment>
                            }
                            <TawreedFormField name="userDto.nationality" className="field col-12 md:col-6" title="lbl_users_nationality">
                                <NationalityDropdown disabled={disabeld} name="userDto.nationality" render="form" rules={{ required: 'msg_field_is_required' }} />
                            </TawreedFormField>
                            <TawreedFormField name="userDto.birthday" title="lbl_users_birthday" className="field col-12 md:col-6">
                                <TawreedCalendar disabled={disabeld} maxDate={getMinDate()} render="form" name="userDto.birthday" />
                            </TawreedFormField>
                            <TawreedFormField name="userDto.prefLangCode" className="field col-12 md:col-6" title="lbl_users_language">
                                <TawreedDropdown disabled={disabeld} name="userDto.prefLangCode" rules={{ required: 'msg_field_is_required' }} render="form" options={constants.constants?.languages ?? []} optionValue="langCode" optionLabel="langName" />
                            </TawreedFormField>
                        </div>
                    </Panel>


                }


                <Panel header={tawreedTranslationService.translate('lbl_partners_address_information')} className="col-12">
                    <div className="grid">
                        <TawreedFormField name="address.stateId" className="field col-12 md:col-12" title="lbl_partners_address_state_id">
                            <StatesDropdown onChange={(e) => { setStateId(e.value); }} disabled={disabeld} name="address.stateId" rules={{ required: 'msg_field_is_required' }} render="form" />
                        </TawreedFormField>

                        <TawreedFormField name="address.cityId" className="field col-12 md:col-12" title="lbl_partners_address_city_id">
                            {/* <CitiesDropdown parentId={stateId} disabled={disabeld} name="address.cityId" rules={{ required: 'msg_field_is_required' }} render="form" /> */}

                            <CitiesDropdown parentId={stateId} disabled={disabeld} name="address.cityId" render="form"
                                rules={{
                                    required: 'msg_field_is_required', validate: (value) => {

                                        var cities = constants.constants?.cities.filter(e => e.stateId === stateId && e.cityId === value) ?? [];
                                        if (cities.length == 0) {
                                            return tawreedTranslationService.translate('msg_field_is_required');
                                        }

                                    }
                                }} />
                        </TawreedFormField>

                        <TawreedFormField name="address.street" className="field col-12" title="lbl_partners_address_street">
                            <TawreedInputText disabled={disabeld} name="address.street" rules={{ required: 'msg_field_is_required' }} render="form" />
                        </TawreedFormField>
                        <TawreedFormField name="address.latitude" className="field col-12 md:col-6" title="lbl_partners_address_latitude">
                            <TawreedInputNumber disabled={disabeld} name="address.latitude" mode="decimal" rules={{ required: 'msg_field_is_required' }} render="form" maxFractionDigits={12} minFractionDigits={0} />
                        </TawreedFormField>
                        <TawreedFormField name="address.longitude" className="field col-12 md:col-6" title="lbl_partners_address_longitude">
                            <TawreedInputNumber disabled={disabeld} name="address.longitude" mode="decimal" rules={{ required: 'msg_field_is_required' }} render="form" maxFractionDigits={12} minFractionDigits={0} />
                        </TawreedFormField>

                        {
                            //id &&
                            <TawreedFormField name="address.location" className="field col-12" title="">
                                <TawreedNavLink disabled={disabeld} render="form" name="address.location">
                                    {tawreedTranslationService.translate('lbl_partners_address_location')}
                                </TawreedNavLink>
                            </TawreedFormField>
                        }
                    </div>
                </Panel>

                {
                    PartnerType.Store === type && (
                        user?.roleCode === "ADMIN" || user?.roleCode === "STORE_ADMIN" || user?.roleCode === "MANAGER" || user?.roleCode === "SALES" || user?.roleCode === "SYS_ADMIN"
                    ) &&
                    <Panel header={tawreedTranslationService.translate('lbl_store_zones_name')} className="col-12">
                        <TawreedFormField name="zone" className="field col-12" title="lbl_zones_name">
                            <ZonesMultiSelect disabled={disabeld} name="zone" render="standalone" value={toZones(zoneList)} onChange={(e: any) => setZoneList(e.value)} />
                        </TawreedFormField>
                    </Panel>
                }
                {
                    PartnerType.Store === type && (
                        user?.roleCode === "ADMIN" || user?.roleCode === "STORE_ADMIN" || user?.roleCode === "MANAGER" || user?.roleCode === "SALES" || user?.roleCode === "SYS_ADMIN"
                    ) &&
                    <Panel header={tawreedTranslationService.translate('lbl_product_categories')} className="col-12">
                        <TawreedFormField name="categories" className="field col-12" title="lbl_product_category">
                            <CategoriesMultiSelect disabled={disabeld} name="categories" render="standalone" value={toProductCategory(storeProductCategoryList)} onChange={(e: any) => { toStoreProductCategoryList(e.target.value) }} />
                        </TawreedFormField>
                    </Panel>
                }
                {
                    id &&
                    <Panel header={tawreedTranslationService.translate('lbl_partners_contact_information')} className="col-12">
                        <div className="grid">
                            <TawreedFormField name="contact.contactName" className="field col-12 md:col-6" title="lbl_partners_contact_name">
                                <TawreedInputText name="contact.contactName" render="form" disabled readOnly />
                            </TawreedFormField>
                            <TawreedFormField name="contact.mobileNo" className="field col-12 md:col-6" title="lbl_partners_contact_mobile">
                                <TawreedInputText name="contact.mobileNo" render="form" disabled readOnly />
                            </TawreedFormField>
                            <TawreedFormField name="contact.email" className="field col-12 md:col-12" title="lbl_partners_contact_email">
                                <TawreedInputText name="contact.email" render="form" disabled readOnly />
                            </TawreedFormField>
                        </div>
                    </Panel>
                }
            </TawreedForm>
            {
                type === PartnerType.Store && id
                && (
                    user?.roleCode === "ADMIN" || user?.roleCode === "MANAGER" || user?.roleCode === "SYS_ADMIN"
                ) &&
                <Panel header={tawreedTranslationService.translate('lbl_store_delivery_mapping_list')}>
                    <StoreDeliveryMappingTable
                        addMappingItem={addMapping}
                        deleteMappingItem={deleteMapping}
                        data={mappingItems}
                        disabeld={disabeld}
                    />
                </Panel>
            }
            {
                type === PartnerType.Store && (
                    user?.roleCode === "ADMIN" || user?.roleCode === "FINANCE" || user?.roleCode === "SYS_ADMIN" || user?.roleCode === "STORE_ADMIN"
                )
                &&
                <Panel header={tawreedTranslationService.translate('lbl_commission_list')}>
                    {priceLists.map((el, idx) => {
                        return (
                            <PriceListForm
                                disabeld={disabeld}
                                key={idx}
                                name={el.priceListName!}
                                partnerId={id ? +id : -1}
                                priceListId={el.priceListId}
                                onChange={changeComission}
                            ></PriceListForm>
                        )
                    })}
                    {/* <Panel header={"d"}
                        className="col-12"> */}
                        <CommissionTable
                            className="col-12 md:col-12"
                            title={"lbl_payment_mode"}
                            partnerId={id ? +id : -1}
                            loading={loading}
                            changeComission={changeComission}
                            deleteComission={deleteComission}
                            reload={reload}
                        >
                        </CommissionTable>
                        <div className="grid">
                        </div>
                    {/* </Panel> */}
                </Panel>
            }
        </React.Fragment>

    );
}
